export default [
  {
    path: "/addmeeting",
    name: "addmeeting",
    component: () => import("@/views/MeetingManagement/AddMeeting.vue"),
    meta: {
      pageTitle: "Add Meeting",
      breadcrumb: [
        {
          text: "Add Meeting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/meetings",
    name: "allmeetings",
    component: () => import("@/views/MeetingManagement/meetings.vue"),
    meta: {
      pageTitle: "All Meetings",
      breadcrumb: [
        {
          text: "All Meetings",
          active: true,
        },
      ],
    },
  },

  {
    path: "/vediochat",
    name: "vediochat",
    component: () => import("@/views/VedioChat.vue"),
    meta: {
      pageTitle: "Vedio",
      breadcrumb: [
        {
          text: "Vedio",
          active: true,
        },
      ],
    },
  },
];
