export default [
  {
    path: "/addpayment",
    name: "addpayment",
    component: () => import("@/views/PaymentManagement/AddPayment.vue"),
    meta: {
      pageTitle: "Add Payment",
      breadcrumb: [
        {
          text: "Add Payment",
          active: true,
        },
      ],
    },
  },
  {
    path: "/payments",
    name: "allpayments",
    component: () => import("@/views/PaymentManagement/payments.vue"),
    meta: {
      pageTitle: "All Payments",
      breadcrumb: [
        {
          text: "All Payments",
          active: true,
        },
      ],
    },
  },
];
